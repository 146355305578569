import payload_plugin_9vwR1SVkh7 from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_WYYyryKyXv from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NgRLQLsEbv from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ZDCBRzKgqf from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_wZsyzOp8ym from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_terser_d88897d5c03bc1af203d1df8bebda2f0/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_22cy9xrUNP from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_06z5ZD5xV7 from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_eupx2gfi86 from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_mQhN8XjppD from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_kI0SkiRRoc from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.7.3_vue@3.5.13_typescript@5.7.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/apps/l4/.nuxt/components.plugin.mjs";
import prefetch_client_FgCYtxbQLW from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0.2.3_eslint@8.57.1_ioredis@5_333ae52b8ad0b81c45f4d87d5e5efd52/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_OHU0vggoM9 from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_NLojWINa3x from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_vite@6.1.0_@types+node@22.13.1_jiti@2.4.2_terser@5.38._b2125d18f4f0ba1d4fbe2f6ed48b8ecd/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import nuxt_plugin_Z8aREu0wjA from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_5clynZCQYE from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@storyblok+nuxt@6.2.2_vue@3.5.13_typescript@5.7.3_/node_modules/@storyblok/nuxt/dist/runtime/plugin.js";
import plugin_wk4vwa7HLu from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/pinia-plugin-persistedstate@4.2.0_@pinia+nuxt@0.7.0_magicast@0.3.5_typescript@5.7.3_vue_792af6ac5a8eac3d4ed09ec343206fce/node_modules/pinia-plugin-persistedstate/dist/nuxt/runtime/plugin.js";
import switch_locale_path_ssr_k2PTOZB9Uy from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_KZYZ1mbvGO from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.34.6_vue@3.5.13_typescript@5.7.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import base64_2hii42QBJd from "/codebuild/output/src1821970661/src/bitbucket.org/magusa/cavu-workspaces/apps/_base/plugins/base64.ts";
export default [
  payload_plugin_9vwR1SVkh7,
  revive_payload_client_WYYyryKyXv,
  unhead_NgRLQLsEbv,
  router_ZDCBRzKgqf,
  _0_siteConfig_wZsyzOp8ym,
  payload_client_22cy9xrUNP,
  navigation_repaint_client_06z5ZD5xV7,
  check_outdated_build_client_eupx2gfi86,
  chunk_reload_client_mQhN8XjppD,
  plugin_vue3_kI0SkiRRoc,
  components_plugin_KR1HBZs4kY,
  prefetch_client_FgCYtxbQLW,
  plugin_OHU0vggoM9,
  plugin_NLojWINa3x,
  nuxt_plugin_Z8aREu0wjA,
  plugin_5clynZCQYE,
  plugin_wk4vwa7HLu,
  switch_locale_path_ssr_k2PTOZB9Uy,
  i18n_KZYZ1mbvGO,
  base64_2hii42QBJd
]
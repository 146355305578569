<template>
    <div v-editable="blok" data-test="NewL4Features">
        <div class="features">
            <div class="features__container md:container">
                <!-- Begin: Features - Header -->

                <h2 class="features__content-heading">{{ blok.title }}</h2>

                <!-- End: Features - Header -->
                <!-- Begin: Features - Content Row -->

                <div class="features__content-row">
                    <template v-for="(block, index) in blok.blocks" :key="index">
                        <div v-if="block" class="features__content-item">
                            <h2 class="features__content-item--title">{{ block.title }}</h2>
                            <div v-if="block.content" class="features__content-item--content">
                                <img
                                    v-if="block.icon == 'meetandgreet'"
                                    src="~/assets/images/meetandgreet-icon.svg"
                                    width="65"
                                    height="48"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="block.icon == 'parkandride'"
                                    src="~/assets/images/parkandride-icon.svg"
                                    width="65"
                                    height="48"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="block.icon == 'onsite'"
                                    src="~/assets/images/onsite-icon.svg"
                                    width="60"
                                    height="48"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="block.icon == 'prices'"
                                    src="~/assets/images/prices-icon.svg"
                                    width="45"
                                    height="45"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="block.icon == 'coverage'"
                                    src="~/assets/images/coverage-icon.svg"
                                    width="45"
                                    height="45"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <img
                                    v-if="block.icon == 'service'"
                                    src="~/assets/images/service-icon.svg"
                                    width="45"
                                    height="45"
                                    class="feature-icon"
                                    loading="lazy"
                                />
                                <RichText :richtext="block.content" />
                            </div>
                        </div>
                    </template>
                </div>

                <!-- End: Features - Content Row -->
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4FeaturesConfig } from '~/types/components/L4Features';

defineProps<{ blok: IL4FeaturesConfig }>();
</script>

<style lang="postcss" scoped>
.features {
    @apply py-12 px-4 md:px-0 bg-[#eae2ed] text-primary;
}

/* Begin: Features - Header */
.features__content-heading {
    @apply mb-4 font-bold text-4xl uppercase;
}
/* End: Features - Header */

.features__content-row {
    @apply flex flex-col sm:flex-row;
}

.features__content-item {
    @apply md:mx-4 mb-4 md:mb-0;
}

.features__content-item--content {
    @apply relative font-medium text-justify text-sm;
    :deep(p) {
        font-size: inherit;
    }
}
.features__content-item--content .feature-icon {
    @apply relative inline-block -top-2 -mb-4 mr-1.5 float-left;
}

.features__content-item--content :deep(.icon) {
    float: left;
}

.features__content-item:first-child {
    @apply ml-0;
}
.features__content-item:last-child {
    @apply mr-0;
}

.features__content-item h2 {
    @apply mb-4 font-semibold text-lg uppercase;
}
</style>

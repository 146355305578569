<template>
    <div v-editable="blok" data-test="NewL4ProductGrid" class="md:container mb-5">
        <div class="ProductGrid-container">
            <div class="handHover input-group ProductGrid-button relative">
                <img src="~/assets/images/banner-parking-icon.svg" alt="Banner Icon" class="iconCarPark options" />
                <button class="right-icon" @click="toggleAction">
                    {{ blok.button_text }}
                </button>
            </div>
            <div v-if="toggled" class="ProductGrid-content">
                <template v-for="product in products" :key="product.id">
                    <NuxtLink :to="domain + localePath(getURL(product))" class="block md:w-[186px]" external>
                        <NuxtImg
                            v-if="product.id"
                            :src="`https://cdn2.looking4.com/media/images/products/${product.id}.jpg`"
                            :title="product.displayName"
                            :alt="`${product.displayName} logo`"
                            format="webp"
                            loading="lazy"
                        />
                        <h3 class="ProductGrid-titles">{{ product.displayName }}</h3>
                    </NuxtLink>
                </template>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4ProductGrid } from '~/types/components/L4ProductGrid';
import type { IL4ApiProduct } from '~/types/components/L4Products';
const props = defineProps<{ blok: IL4ProductGrid }>();
const toggled = ref(false);

const route: any = useRoute();
const localePath = useLocalePath();
const domain = useRuntimeConfig().public.SITE_URL;

const products = inject<Ref<IL4ApiProduct[]>>('apiParkingProducts');

// Toggle to show/hide car park options.
function toggleAction() {
    return (toggled.value = !toggled.value);
}

function getURL(product: IL4ApiProduct) {
    const productId = product.id;
    const productName = product.titleSlug;
    return `/${route.params.type}/${route.params.location}/${productName}/${productId}`;
}

watch(
    () => props.blok,
    () => {
        if (props.blok.open_state) toggled.value = true;
    },
    { immediate: true }
);
</script>

<style lang="postcss" scoped>
.ProductGrid-container {
    @apply bg-white max-w-[65.5rem] my-5 mx-auto text-primary flex items-center justify-center flex-wrap h-full p-5;
}
.ProductGrid-button {
    @apply bg-primary text-white flex justify-center h-11 font-normal text-2xl;

    & button {
        @apply w-full;
    }
}
.ProductGrid-titles {
    @apply text-sm md:text-lg/[22px] font-bold text-bodypurple;
}
.ProductGrid-content {
    @apply grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-6 pt-6;
}
.right-icon::after {
    content: '';
    bottom: calc(50% - 0.9rem);

    @apply absolute right-[10%] h-0 w-0 border-transparent md:border-t-white border-8;
}

.handHover {
    &:hover {
        @apply bg-handHover;
    }
    .options {
        @apply hidden md:block absolute top-[2px] left-8 h-10;
    }
}
</style>

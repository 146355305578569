<template>
    <div v-editable="blok" data-test="NewL4ProductMap" class="md:container my-5">
        <div class="ProductMap-container">
            <div class="handHover input-group ProductMap-button relative">
                <img src="~/assets/images/banner-map-icon.svg" alt="Banner Icon" class="iconMap options absolute" />
                <button class="right-icon" @click="toggleAction">
                    {{ blok.button_text }}
                </button>
            </div>
            <ClientOnly>
                <LazyProductMap v-if="toggled" :airport="blok.airport" :products="products" class="pt-5" />
            </ClientOnly>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4ProductMap } from '~/types/components/L4ProductMap';
import type { IL4ApiProduct } from '~/types/components/L4Products';

defineProps<{ blok: IL4ProductMap }>();
const toggled = ref<boolean>(false);

const products = inject<Ref<IL4ApiProduct[]>>('apiParkingProducts');

// Toggle to show/hide car park options.
function toggleAction(): boolean {
    return (toggled.value = !toggled.value);
}
</script>

<style lang="postcss" scoped>
.ProductMap-container {
    @apply p-5 bg-white;
}
.ProductMap-button {
    @apply bg-primary text-white flex justify-center h-11 font-normal text-2xl;

    & button {
        @apply w-full;
    }
}
.right-icon::after {
    content: '';
    bottom: calc(50% - 0.9rem);

    @apply absolute right-[10%] h-0 w-0 border-transparent md:border-t-white border-8;
}

.handHover {
    &:hover {
        @apply bg-handHover;
    }
    .options {
        @apply hidden md:block absolute top-[2px] left-8 h-10;
    }
}
</style>

<template>
    <section v-if="usp" v-editable="blok" class="usp-block airport-page-section">
        <div class="usp-block__container md:container">
            <h2 class="usp-block__title airport-page-title">{{ usp.title }}</h2>
            <ul class="usp-block__list">
                <li v-for="item in usp.list" :key="item._uid" class="usp-block__item">
                    <div class="usp-block__item-image" :class="item.variant">
                        <img
                            v-if="item.variant === 'best-prices-s'"
                            src="~/assets/images/piggybank-pound-icon.svg"
                            alt="Piggybank Pound Icon"
                            class="benefits-icon piggybank-s"
                        />
                        <img
                            v-else-if="item.variant === 'best-prices'"
                            src="~/assets/images/piggybank-euro-icon.svg"
                            alt="Piggybank Euro Icon"
                            class="benefits-icon piggybank"
                        />
                        <img
                            v-else-if="item.variant === 'best-prices-d'"
                            src="~/assets/images/piggybank-dollar-icon.svg"
                            alt="Piggybank Dollar Icon"
                            class="benefits-icon piggybank-d"
                        />
                        <img
                            v-else-if="item.variant === 'best-prices-fr'"
                            src="~/assets/images/piggybank-franc-icon.svg"
                            alt="Piggybank Franc Icon"
                            class="benefits-icon piggybank-fr"
                        />
                        <img
                            v-else-if="item.variant === 'best-prices-r'"
                            src="~/assets/images/piggybank-rupee-icon.svg"
                            alt="Piggybank Rupee Icon"
                            class="benefits-icon piggybank-r"
                        />
                        <img
                            v-else-if="item.variant === 'best-prices-zl'"
                            src="~/assets/images/piggybank-zl-icon.svg"
                            alt="Piggybank zl Icon"
                            class="benefits-icon piggybank-zl"
                        />
                        <img
                            v-if="item.variant === 'secure-car-parks'"
                            src="~/assets/images/shield-icon.svg"
                            alt="Shield Icon"
                            class="benefits-icon shield"
                        />
                        <img
                            v-if="item.variant === 'save-booking-10' || item.variant === 'save-booking'"
                            src="~/assets/images/calendar-discount-icon.svg"
                            alt="Calendar Discount Icon"
                            class="benefits-icon calendar-discount"
                        />
                        <img
                            v-if="item.variant === 'excellent-service'"
                            src="~/assets/images/star-badge-icon.svg"
                            alt="Star Badge Icon"
                            class="benefits-icon star-badge"
                        />
                        <img
                            v-if="item.variant === 'fifteen-countries'"
                            src="~/assets/images/world-icon.svg"
                            alt="World Icon"
                            class="benefits-icon world"
                        />
                    </div>
                    <span class="usp-block__item-text">{{ filterText(item.title) }}</span>
                </li>
            </ul>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IL4BlockUSP, IL4UPS } from '~/types/components/L4BlockUSP';
import type { ComputedRef } from 'vue';

const { blok } = defineProps<{ blok: IL4BlockUSP }>();
const usp: ComputedRef<IL4UPS> = computed(() => blok?.block?.content);

function filterText(text: string) {
    return text.replace('{airport}', blok.airport_code || '');
}
</script>

<style lang="postcss" scoped>
.usp-block {
    &__container {
        @apply md:container text-primary;
    }
    &__title {
        @apply mb-6 md:mb-10;
    }
    &__list {
        @apply md:grid gap-y-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5;
    }
    &__item {
        @apply relative  text-center flex md:flex-col items-center;
        &:not(:last-child) {
            @apply mb-4 md:mb-0;
        }
        &-text {
            @apply text-sm font-sans text-[#0A0A0A] md:mt-2 md:px-4 pb-0 text-left md:text-center;
        }
        &-image {
            @apply h-10 w-10 md:h-20 md:w-20 px-1 bg-purple-100 md:bg-transparent mr-2 md:mr-0 rounded-full flex items-center justify-center shrink-0;
        }
    }
}
</style>

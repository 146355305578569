<template>
    <div v-editable="blok" data-test="NewL4Benefits">
        <div class="benefits-container md:container">
            <h2 class="benefits-title">{{ blok.title }}</h2>
            <ul class="benefits-ul">
                <li v-for="(item, index) in blok.benefits" :key="item._uid" class="benefits-li">
                    <img
                        v-if="index === 0 && item.variant === 'best-prices-s'"
                        src="~/assets/images/piggybank-pound-icon.svg"
                        alt="Piggybank Pound Icon"
                        class="benefits-icon piggybank-s"
                    />
                    <img
                        v-else-if="index === 0 && item.variant === 'best-prices'"
                        src="~/assets/images/piggybank-euro-icon.svg"
                        alt="Piggybank Euro Icon"
                        class="benefits-icon piggybank"
                    />
                    <img
                        v-else-if="index === 0 && item.variant === 'best-prices-d'"
                        src="~/assets/images/piggybank-dollar-icon.svg"
                        alt="Piggybank Dollar Icon"
                        class="benefits-icon piggybank-d"
                    />
                    <img
                        v-else-if="index === 0 && item.variant === 'best-prices-fr'"
                        src="~/assets/images/piggybank-franc-icon.svg"
                        alt="Piggybank Franc Icon"
                        class="benefits-icon piggybank-fr"
                    />
                    <img
                        v-else-if="index === 0 && item.variant === 'best-prices-r'"
                        src="~/assets/images/piggybank-rupee-icon.svg"
                        alt="Piggybank Rupee Icon"
                        class="benefits-icon piggybank-r"
                    />
                    <img
                        v-else-if="index === 0 && item.variant === 'best-prices-zl'"
                        src="~/assets/images/piggybank-zl-icon.svg"
                        alt="Piggybank zl Icon"
                        class="benefits-icon piggybank-zl"
                    />
                    <img
                        v-if="index === 1"
                        src="~/assets/images/shield-icon.svg"
                        alt="Shield Icon"
                        class="benefits-icon shield"
                    />
                    <img
                        v-if="index === 2"
                        src="~/assets/images/calendar-discount-icon.svg"
                        alt="Calendar Discount Icon"
                        class="benefits-icon calendar-discount"
                    />
                    <img
                        v-if="index === 3"
                        src="~/assets/images/star-badge-icon.svg"
                        alt="Star Badge Icon"
                        class="benefits-icon star-badge"
                    />
                    <img
                        v-if="index === 4"
                        src="~/assets/images/world-icon.svg"
                        alt="World Icon"
                        class="benefits-icon world"
                    />
                    <span class="benefits-span">{{ filterText(item.text) }}</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IL4Benefits } from '~/types/components/L4Benefits';

defineProps<{ blok: IL4Benefits }>();

function filterText(text: string) {
    return text.replace('{airport}', '');
}
</script>

<style lang="postcss" scoped>
.benefits-title {
    @apply mb-4 px-4 text-2xl text-center;
}

.benefits-container {
    @apply bg-white mx-auto my-5 text-primary py-6;
}
.benefits-ul {
    @apply grid gap-y-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-5;
}

.benefits-li {
    @apply relative mb-4 text-center flex flex-col items-center;
}

.benefits-span {
    @apply text-sm font-sans text-neutral-950 mt-2 px-2 pb-0 flex items-center;
}

.benefits-li {
    .benefits-icon {
        @apply h-16;
    }
}
</style>
